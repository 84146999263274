import React from "react"
import SEO from "../../components/seo"
import EventHeader from "../../components/EventHeader"
import Footer from "../../components/Footer"

import { isAndroid, isIOS, isDesktop } from "react-device-detect"

import { StaticImage } from "gatsby-plugin-image"

const SpringClean = () => {
  return (
    <>
      <EventHeader imageSrc="/images/3.jpg" halfHeight />
      <SEO title="CleanUp - Spring Clean" />
      <div class="relative py-16 bg-white">
        <div class="relative px-4 py-20 sm:px-6 lg:px max-w-6xl mx-auto -mt-32 bg-white">
          <div class="text-lg mx-auto leading-tight">
            <h1>
              <span class="mt-2 block text-5xl text-center font-serif  font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                CleanUp
              </span>
              <span class="mt-5 mb-10 block text-5xl italic text-center font-serif font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                ‘Spring Clean’ Weekend
              </span>
            </h1>
            <h2 class="mt-0 block text-base text-3xl text-center text-green-600 font-bold tracking-widest uppercase mb-5">
              26th / 27th March 2022
            </h2>
          </div>
          <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <div class="text-center">
              <h3>
                Do you love where you live? Sevenoaks District residents are
                being invited to roll up their sleeves this spring and help
                tackle litter.
              </h3>
            </div>
            <h3>#CleanupSevenoaks</h3>
            <p>
              Anyone who shares a picture of the litter they collect on the
              26th/27th March on FB or Instagram (use the hashtag!) will be
              entered into a draw to win a prize from one of the local
              businesses who donate prizes (lots of free coffee & cake!)
            </p>

            <h4>Litter picking equipment</h4>
            <p>
              Packs will be delivered to all schools which expressed an interest
              in taking part and litter pickers will also be available from Life
              on High in Sevenoaks on a first come, first served basis.
            </p>

            <h4>What to do with your collected litter</h4>
            <p>
              Litter picking heroes are kindly requested to leave their bags of
              litter next to any Sevenoaks District Council bin from where it
              will be collected as soon as possible. For any large items, or
              specific collection requests, please email
              direct.services@sevenoaks.gov.uk or ring 01732 227000.
            </p>

            <h4>This year's sponsors</h4>

            <ul>
              <li>Lombard & Sherborne</li>
              <li>St Clere Estate</li>
              <li>Mr Ames Tea</li>
              <li>COOK </li>
              <li>Life on High </li>
              <li>Malabar </li>
              <li>Nando’s </li>
              <li>Pizza Uno </li>
              <li>Raj Bari </li>
              <li>Tom Bell Fish and Chips </li>
              <li>SPN </li>
              <li>Riverhill </li>
              <li>Horncastles </li>
              <li>Lorrimers </li>
              <li>Darcey’s Kitchen</li>
              <li>Anna Smith golf pro </li>
              <li>Hutch Deli in Ightham </li>
              <li>National Trust </li>
              <li>Chevening House </li>
              <li>Hever Castle</li>
              <li>A grateful Edenbridge resident</li>
              <li>The Bridge Estate Agents, Edenbridge</li>
              <li>Eden Emporium, Edenbridge</li>
              <li>Design Centre, Edenbridge</li>
              <li>Edenbridge Foodbank</li>
              <li>Sustainable Edenbridge</li>
            </ul>

            <dt class="mt-20 text-sm font-medium text-gray-500">Attachments</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <div class="w-0 flex-1 flex items-center">
                    <svg
                      class="flex-shrink-0 h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    <span class="ml-2 flex-1 w-0 truncate">
                      Risk assessment (docx)
                    </span>
                  </div>
                  <div class="ml-4 flex-shrink-0">
                    <a
                      href="/files/risk_assessment_clean_up.docx"
                      class="font-medium text-blue-600 hover:text-blue-500"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download
                    </a>
                  </div>
                </li>
              </ul>
            </dd>
          </div>

          <div className="bg-white">
            <div className="max-w-5xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
              <div className="bg-green-700 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4">
                <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
                  <div className="lg:self-center">
                    <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                      <span className="block">Get the App</span>
                    </h2>
                    <p className="mt-4 text-xl leading-6 text-indigo-200 text-white">
                      This year it's easier than ever to log your litter
                      collection
                    </p>

                    {isIOS && (
                      <a
                        href="https://apps.apple.com/gb/app/cleanup-kent/id1615419560"
                        className="mt-8 bg-white w-full justify-center content-center text-center border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
                      >
                        Download from the app store
                      </a>
                    )}

                    {isDesktop && (
                      <span
                        href="#"
                        className="mt-8 text-center bg-white border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
                      >
                        Open this page on your phone to download the app
                      </span>
                    )}
                  </div>

                  <dl className="mt-10 space-y-10">
                    <div className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                          <svg
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g transform="matrix(1.3333333333333333,0,0,1.3333333333333333,0,0)">
                              <path
                                d="M8.500 7.000 A3.500 3.500 0 1 0 15.500 7.000 A3.500 3.500 0 1 0 8.500 7.000 Z"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M12,.5a6.856,6.856,0,0,1,6.855,6.856c0,3.215-4.942,11.185-6.434,13.517a.5.5,0,0,1-.842,0c-1.492-2.332-6.434-10.3-6.434-13.517A6.855,6.855,0,0,1,12,.5Z"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M17,19.7c3.848.324,6.5,1.009,6.5,1.8,0,1.105-5.148,2-11.5,2S.5,22.605.5,21.5c0-.79,2.635-1.473,6.458-1.8"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-white">
                          Logs your litter collections on the map, so we can
                          easily direct Sevenoaks District Council to them
                        </p>
                      </dt>
                    </div>
                    <div className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 32 32"
                          >
                            <g transform="matrix(1.3333333333333333,0,0,1.3333333333333333,0,0)">
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M14.58 15L13.78 14.71C13.22 14.5 13.31 13.02 13.56 12.71C13.959 12.2788 14.2623 11.7681 14.4501 11.2115C14.638 10.6548 14.7061 10.0648 14.65 9.48C14.6844 9.10769 14.6419 8.73229 14.5252 8.37708C14.4085 8.02186 14.2201 7.6944 13.9716 7.415C13.7231 7.1356 13.4199 6.91022 13.0808 6.75282C12.7416 6.59543 12.3738 6.50937 12 6.5C11.6244 6.50916 11.2547 6.59576 10.9141 6.75438C10.5734 6.913 10.2692 7.14023 10.0205 7.42183C9.77172 7.70344 9.58377 8.03337 9.46841 8.39096C9.35304 8.74855 9.31273 9.12611 9.35 9.5C9.29391 10.0848 9.36202 10.6748 9.54986 11.2315C9.7377 11.7881 10.041 12.2988 10.44 12.73C10.69 13 10.78 14.48 10.22 14.73C8.13 15.45 6.46 16 6.07 16.8C5.70273 17.6533 5.50896 18.5711 5.5 19.5H17.5"
                              ></path>
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M18.5 19.5H5.5"
                              ></path>
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M18.5 15.09V4.5C18.5 3.96957 18.2893 3.46086 17.9142 3.08579C17.5391 2.71071 17.0304 2.5 16.5 2.5H7.5C6.96957 2.5 6.46086 2.71071 6.08579 3.08579C5.71071 3.46086 5.5 3.96957 5.5 4.5V21.5C5.5 22.0304 5.71071 22.5391 6.08579 22.9142C6.46086 23.2893 6.96957 23.5 7.5 23.5H16.5C17.0304 23.5 17.5391 23.2893 17.9142 22.9142C18.2893 22.5391 18.5 22.0304 18.5 21.5V19.5"
                              ></path>
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M0.5 2.5H1.5"
                              ></path>
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M2.5 1.5V0.5"
                              ></path>
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M4.5 2.5H3.5"
                              ></path>
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M2.5 3.5V4.5"
                              ></path>
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M19.71 16.29L18.21 14.79C18.1023 14.6777 17.9736 14.5877 17.8311 14.5252C17.6887 14.4627 17.5353 14.4288 17.3798 14.4256C17.2242 14.4223 17.0696 14.4497 16.9246 14.5062C16.7797 14.5628 16.6473 14.6473 16.535 14.755C16.4227 14.8626 16.3328 14.9914 16.2702 15.1338C16.2077 15.2763 16.1739 15.4297 16.1706 15.5852C16.164 15.8993 16.2825 16.2032 16.5 16.43C18.71 18.73 18.15 19.85 20 21.71L22 23.5"
                              ></path>
                              <path
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M18.5 10.5L21.79 14.29C22.0152 14.5141 22.1939 14.7806 22.3158 15.0741C22.4376 15.3676 22.5002 15.6822 22.5 16V17.5C22.5102 17.8862 22.605 18.2655 22.7778 18.6111C22.9506 18.9567 23.1971 19.2601 23.5 19.5"
                              ></path>
                              <g>
                                <path
                                  stroke="#fff"
                                  d="M12 21.75C11.8619 21.75 11.75 21.6381 11.75 21.5C11.75 21.3619 11.8619 21.25 12 21.25"
                                ></path>
                                <path
                                  stroke="#fff"
                                  d="M12 21.75C12.1381 21.75 12.25 21.6381 12.25 21.5C12.25 21.3619 12.1381 21.25 12 21.25"
                                ></path>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-white">
                          Easily add and see photos of local rubbish, helping
                          other volunteers locate it easily
                        </p>
                      </dt>
                    </div>
                    <div className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                          <svg
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g transform="matrix(1.3333333333333333,0,0,1.3333333333333333,0,0)">
                              <path
                                d="M16,20.5l-1.213-4.042A1.43,1.43,0,0,0,13.5,15.5h-3a1.43,1.43,0,0,0-1.287.958L8,20.5"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M12 15.498L12 12.501"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M12.706.931,14.245,4.5H17.24a.735.735,0,0,1,.516,1.283L15.158,8.1l1.44,3.313a.785.785,0,0,1-1.117.982L12,10.434,8.52,12.4A.785.785,0,0,1,7.4,11.413L8.843,8.1l-2.6-2.315A.735.735,0,0,1,6.758,4.5h3L11.294.931A.8.8,0,0,1,12.706.931Z"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M18,22a1.5,1.5,0,0,1-1.5,1.5h-9a1.5,1.5,0,0,1,0-3h9A1.5,1.5,0,0,1,18,22Z"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-white">
                          Easy entry to win one of the prizes offered by our
                          great Sponsors
                        </p>
                      </dt>
                    </div>
                  </dl>

                  {isAndroid && (
                    <>
                      <p className="text-md mt-10 leading-6 font-medium text-white">
                        Please note due to a backlog of reviews on the Google
                        Play store, we were unable to host the app for download
                        there before the event.{" "}
                        <span class="font-bold">
                          We offer it for direct download here instead, but your
                          device may warn you or ask for permission before
                          installation.
                        </span>
                      </p>
                      <a
                        href="https://firebasestorage.googleapis.com/v0/b/cleanup-2f550.appspot.com/o/6.apk?alt=media&token=93595ef6-3d9e-4073-a67e-eb762674cde9"
                        className="mt-8 bg-white w-full justify-center content-center text-center border border-transparent rounded-md shadow px-5 py-3 inline-flex items-center text-base font-medium text-indigo-600 hover:bg-indigo-50"
                      >
                        Download the app
                      </a>
                    </>
                  )}
                </div>
                <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1 hidden md:flex">
                  <StaticImage
                    className="h-fit transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                    src="../images/app-home-screen.png"
                    alt="App screenshot"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SpringClean
